@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}
.Kalend__main, .Kalend__main * {
  font-family: 'Open Sans', sans-serif !important;
}
input {
  font-family: 'Open Sans', sans-serif !important;
}

label {
  text-transform: none !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

*:focus, *:focus-visible, *:focus-within {
  box-shadow:none !important;
  outline: none !important;
  border-color:#0758ff !important;
}

a, a:hover, a:visited {
  color: #0758ff;
}

.p-inline-message .p-inline-message-text {
  text-align: left !important;
}

.p-inline-message.p-inline-message-warn {
  background: #ffc600 !important;
  color: #fff !important;
  text-align: left !important;
}

.p-button, button.p-button, button.p-button:hover {
  background-color: #0758ff !important;
  border-color: #0758ff !important;
}

button.p-button.p-button-outlined, .p-button-outlined:hover {
  border-color: #0758ff;
  color: #0758ff !important;
  background: transparent !important;
}

.p-button.p-button-success, button.p-button.p-button-success {
  background-color: #0758ff !important;
  border-color: #0758ff !important;
}

.p-button.p-button-warning, button.p-button.p-button-warning {
  background-color: #ffc600 !important;
  border-color: #ffc600 !important;
}

.p-button.p-button-secondary, button.p-button.p-button-secondary {
  background-color: #64748a !important;
  border-color: #64748a !important;
}

.p-checkbox {

}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0758ff !important;
  background: #0758ff !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #0758ff !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #0758ff !important;
}

.p-inputtext:enabled:hover {
  border-color: #0758ff !important;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 15px !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #0758ff !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #0758ff !important;
  box-shadow: none !important;
}

.transparent {
  background: transparent !important;
}

.p-tabview .p-tabview-nav-content ul li.p-tabview-selected a {
  border-bottom: 3px solid #0758ff !important;
  color: #0758ff !important;
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
  border: 0 !important
}

table tr, table th, table td, .p-paginator {
  background: transparent !important;
}

table .p-button-icon-only {
  border-radius:100% !important;
  padding: 0.5375rem 0.875rem !important;
}

.p-datatable .p-sortable-column.p-highlight, .p-datatable .p-sortable-column.p-highlight * {
  color: #0758ff !important;
}

.p-menubar .activeMenu {
  border-bottom: 2px solid #0758ff !important;
}

.p-menubar .activeMenu * {
  font-weight: bold;
  color: #0758ff !important;
}

.p-dialog-content {
  padding-top: 2rem !important;
}

.p-panel .p-panel-header .p-panel-title {
  color: #0758ff;
}

body.outside {
}

.LoginPage {
  background: transparent !important;
}

.LoginPage img {
  max-width:200px;
}

.LoginPage .p-panel {
  background: #fff;
  padding:30px !important;
  border-radius: 15px;
}

.LoginPage .p-text-center a.p-text-light {
  color: #0758ff !important;
}

.DashboardPage .right {
  background: #0740a5;
}

.ModuleDashboardPage .right {
  background: #0758ff;
}

.right a.w-100:hover .p-card {
  background: #5d739a;
  color: #fff;
  color: #fff;
}

.text-primary {
  color: #0758ff;
}

.EntryzoneLiveList .p-panel.status-PASS, .EntryzoneLiveList .p-panel.status-PASS * {
  background: #0758ff;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-UNID, .EntryzoneLiveList .p-panel.status-UNID * {
  background: #ffc600;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-BLCK, .EntryzoneLiveList .p-panel.status-BLCK * {
  background: #f31a26;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-STORNO, .EntryzoneLiveList .p-panel.status-STORNO * {
  background: #6c757d;
  border: 0;
  color: #fff;
}

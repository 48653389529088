@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: rgb(243, 244, 245) !important;
  padding: 0;
  margin: 0;
  /*position: relative;*/
  height: 100%;
  overflow-x: hidden;
}

#root {
  display: none;
}

#root, #app {
  height: 100%;
  /*overflow-x: hidden;*/
  /*max-width: 1900px;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
}

a {
  text-decoration: none;
}

a, a:hover, a:visited {
  color: #883cae;
  cursor: pointer !important;
}

a:focus, button:focus {
  box-shadow: none !important;
}

img {
  max-width: 100%;
}

hr {
  opacity: 0.2;
  border-bottom-width: 0px !important;
}

p {
  line-height: 1.5em;
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-warning {
  color: #F59E0B !important;
}

.p-bg-transparent {
  background: transparent !important;
}

.p-toast {
  z-index: 9999 !important;
}

.p-toast.p-component {
  max-width: 400px !important;
  margin-left: 0;
  margin-right: 0;
}

.p-tag-warning, .p-button-warning {
  color: #fff !important;
}

.p-fileupload .p-fileupload-buttonbar {
  border: 0 !important;
  padding: 0 !important;
  background: none !important;
}

.p-fileupload .p-fileupload-content {
  border: 0 !important;
  padding: 0 !important;
  background: none !important;

}

.p-fileupload .p-progressbar {
  display: none !important;
}

.p-fileupload .p-fileupload-filename {
  padding: 0 !important;
}

.p-fileupload .p-button-label {
  max-width: 100%;
  max-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.p-disabled, .disabled {
  opacity: 0.5 !important;
}

.p-position-relative {
  position: relative !important;
}

legend {
  width: auto !important;
  margin: 0 0 0 20px;
  font-size: 1em;
}

label {
  text-transform: uppercase;
  font-size: 13.3px !important;
  font-weight: 400;
}

a.p-button. {
  padding: 0.65rem;
  border-radius: 3px;
}

.p-component {
  max-width: 1900px !important;
  margin-left: auto;
  margin-right: auto;
}

.p-field {
  margin: 0;
}

.p-breadcrumb {
  padding-top: 0 !important;
  background: transparent !important;
  border: 0 !important;
}

.p-panel {
}

.p-panel .p-panel-content {
  padding: 0rem !important;
  border: none !important;
  background: none !important;
  position: relative;
}

.p-panel .p-panel-content .p-button-outlined.fixed {
  position: absolute;
  right: 50px;
  top: -62px;
}

.p-panel .p-panel-header {
  border: 0 !important;
  background: 0 !important;
  padding: 0 !important;
  /*margin: 0 0 2em 0 !important;*/
}

.p-panel .p-panel-header .p-panel-title {
  color: #883cae;
  font-size: 1.5em;
  font-weight: bold !important;
}

.panel-highlight {
  background: rgb(243, 244, 245);
  border-radius: 4px;
}

.p-menubar {
  border: 0 !important;
  background: #fff !important;
  border-radius: 0 !important;
  max-width: 1917px !important;
}

.p-menubar .p-menubar-start {
  margin-right: 20px;
}

.p-menubar .p-menuitem-link {
  padding: 18px 5px !important;
}

.p-menubar .p-submenu-list {
  width: 250px !important;
}

.p-menubar .p-submenu-list .p-menuitem-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.p-menubar .activeMenu {
  border-bottom: 2px solid #883cae !important;
}

.p-menubar .activeMenu * {
  font-weight: bold;
  color: #883cae !important;
}

.p-dropdown {

}

.p-dropdown.p-error {
  border: 1px solid #dc3545 !important;
}

.p-dropdown-panel.p-component, p-connected-overlay-enter-done {
  z-index: 8999 !important;
}

.p-autocomplete-panel.p-component.p-connected-overlay-enter-done {
  z-index: 8999 !important;
}

.p-multiselect-panel .p-multiselect-item {
  display: block;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin: 0;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 3px !important;
}

.p-avatar-circle {
  border-radius: 100% !important;
}

.p-inline-message {
  position: relative;
}

.p-inline-message .p-inline-message-icon {
  display: none;
}

.p-inline-message .p-inline-message-text {
  font-size: 1.0em !important;
  text-align: center !important;
}

.p-tabview.nav li.p-disabled, .p-tabview.menu li.p-disabled {
  display: none;
}

.p-tabview-nav {
  background: transparent !important;
  border: 0 !important
}

.p-tabview.nav .p-tabview-nav-container {
  border-top: 1px solid #e6e9ec !important;
  border-bottom: 1px solid #e6e9ec !important;
  background: #fff;
}

.p-tabview.nav .p-tabview-panels {
  background-color: #fff !important;
}

.p-tabview.nav .p-button-outlined.fixed {
  position: absolute;
  right: 35px;
  top: 58px;
  border: 0 !important;
  z-index: 998 !important;
}

.p-tabview .p-tabview-nav-content ul li.p-tabview-selected a {
  color: #883cae !important;
  background-color: transparent !important;
  border-bottom: 3px solid #883cae !important;
}

.p-tabview.menu {

}

.p-tabview.menu .p-tabview-nav-link {
  background-color: transparent !important;
}

.p-tabview.menu .p-tabview-panels {
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.scale-small .p-tabview .p-button-outlined.fixed {
  top: 45px;
}

.p-tabview .p-button-outlined.fixed:hover {
  background: transparent !important;
}

.p-tabview .p-tabview-nav {
  background: #fff;
  border: 0 !important;
}

.p-tabview .p-tabview-nav .p-tabview-title {
  padding-left: 10px;
}

.p-tabview .p-tabview-nav a {
  border: 0 !important;
  font-weight: normal !important;
  /*background-color: rgb(243, 244, 245) !important;*/
}

.p-tabview .p-tabview-nav .p-tabview-selected a {
  background-color: #fff !important;
}

.p-tabview .p-tabview-nav .p-tabview-selected a {
  border: 0 !important;
  color: #883cae !important;
}

.p-datatable-loading-overlay {
  border-radius: 3px !important;
}

table th.p-filter-column, table th.p-filter-disabled {
  border: 0 !important;
  background: rgb(243, 244, 245) !important;
  border-top: 1px solid #fff !important;
}

table th.p-sortable-column, table th.p-sortable-disabled {
  border: 0 !important;
  background: rgb(243, 244, 245) !important;
}

table th.p-filter-column .p-column-filter-element {
  min-width: 90px;
  max-width: 220px;
}

table thead tr th:first-child {
  border-top-left-radius: 3px !important;
}

table thead tr th {
  border-top: none !important;
}

table thead tr th:last-child {
  border-top-right-radius: 3px !important;
}

table tbody tr {
  border-top: 2px solid rgb(243, 244, 245) !important;
  border-bottom: 2px solid rgb(243, 244, 245) !important;
}

table tr, table td, .p-paginator {
  background: transparent !important;
}

table .p-filter-column {
}

table .p-filter-column .p-column-filter-menu-button {
  display: none;
}

table .p-filter-column .p-dropdown {
  width: 100%;
}

table .p-datatable-emptymessage {
  border-bottom: 1px solid rgb(243, 244, 245);
}

table tr.p-row-expanded {
  border-top: 1px solid rgb(243, 244, 245);
}

table tr.p-row-expanded table {
  font-size: 0.9em;
}

table tr.p-row-expanded table td, table tr.p-row-expanded table th {
  padding: 0.5rem !important;
}

table .p-inplace {
  position: relative;
}

table .p-inplace.text-danger .p-inplace-display {
  border: 1px solid #dc3545;
}

table .p-inplace.text-danger .p-inplace-display:hover {
  color: #fff;
  background: #dc3545;
}

table .p-inplace .p-inplace-content {
  display: block;
  white-space: normal !important;
  width: 280px;
}

table .p-inplace .p-inplace-content .p-button.p-inplace-content-close {
  padding: 0 !important;
  border: 0 !important;
  width: auto !important;
  color: #dc3545 !important;
  background: none !important;
  position: absolute;
  right: 0px;
  top: 0px;
}

table tr th {
  white-space: nowrap !important;
  background: #f3f4f5 !important;
  border-bottom: 1px solid white !important;
}

table tr td {
  background: #fff !important;
  border: none !important;
}

table tr.tr-error td.p-selection-column {
  background: #dc3545 !important;
}

table tr.tr-success td.p-selection-column {
  background: #28a745 !important;
}

table tr.p-row-odd td {
  background: rgb(243, 244, 245) !important;
}

table .p-highlight {
  color: inherit !important;
}

.p-paginator {
  border: 0 !important;
}

.p-paginator.p-paginator-bottom {
  border: 1px solid rgb(243, 244, 245) !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.p-toolbar {
  padding: 0;
  border: 0;
  background: none;
}

.p-sidebar {
  max-width: 100%;
  background: rgb(243, 244, 245) !important;
}

.p-sidebar.p-component {
  margin-left: 0;
  margin-right: 0;
}

.p-sidebar .p-sidebar-header {
  padding: 0 !important;
  position: static !important;
}

.p-sidebar .p-sidebar-header button {
  position: absolute;
  z-index: 9999;
  top: 18px;
  right: 20px;
  background: #e9ecef !important;
}

.p-sidebar .p-sidebar-header button:focus {
  box-shadow: none !important;
}

.p-sidebar .p-sidebar-content {
  background: rgb(243, 244, 245) !important
}

.p-sidebar .p-sidebar-content .p-breadcrumb {
  display: none !important;
}

.p-sidebar-content .p-panel {
  padding: 1.25rem;
  /*border: 1px solid #dee2e6;*/
  background: #ffffff;
  color: #212529;
  border-radius: 4px;
}

.p-sidebar-content .p-panel h3 {
  margin-top: 5px;
  margin-bottom: 10px;
}

.p-dialog {

}

.p-dialog .p-dialog-header {
  background: rgb(243, 244, 245) !important;
}

.p-dialog .p-dialog-content {
  background: rgb(243, 244, 245) !important
}

.p-dialog .p-dialog-content .p-breadcrumb {
  display: none !important;
}

.p-dialog .p-dialog-content h3 {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  display: none;
}

.p-blockui-container {
  width: 100%;
}

.p-timeline.no-alternate-column .p-timeline-event-opposite {
  display: none
}

.p-timeline .p-timeline-event-content {
  padding-right: 0 !important;
}

.p-colorpicker {
  width: 100% !important;
}

.p-colorpicker input {
  height: 41px !important;
}

.p-card {
  box-shadow: none !important;
}

.p-card .p-card-content {
  padding: 0 !important;
}

form .inplace-input .p-inplace {
  position: relative;
}

form .inplace-input .p-inplace-display {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

form .inplace-input .p-inplace.text-danger .p-inplace-display {
  border: 1px solid #dc3545;
  background: #dc3545;
  color: #fff;
}

form .inplace-input .p-inplace.text-danger .p-inplace-display:hover {
  color: #fff;
  background: #dc3545;
}

form .inplace-input .p-inplace.text-success .p-inplace-display {
  border: 1px solid #28a745;
  background: #28a745;
  color: #fff;
}

form .inplace-input .p-inplace.text-success .p-inplace-display:hover {
  color: #fff;
  background: #28a745;
}


form .inplace-input .p-inplace .p-inplace-content small.d-block {
  margin-top: -6px !important;
}

form .inplace-input .p-inplace .p-inplace-content {
  display: block !important;
}

form .inplace-input .p-inplace .p-inplace-content .p-button.p-inplace-content-close {
  padding: 0 !important;
  border: 0 !important;
  width: auto !important;
  color: #ccc !important;
  background: none !important;
  position: absolute;
  right: 0px;
  top: -32px;
}

/* */

nav .p-menubar {

}

nav .p-menubar .p-inputgroup {
  border-bottom: 1px solid transparent;
}

nav .p-menubar .p-menubar-end .p-dropdown {
  max-width: 220px;
}

.p-tooltip-right {
  margin-left: 5px !important;
}

/* layout */

.DashboardPage {
  padding: 0 !important;
  /*height: 100%;*/
}

.DashboardPage .p-grid.p-d-flex.p-flex-wrap {
  height: 100%;
  margin-top: 0;
}

.DashboardPage .right {
  background: #883cae;
}

.ModuleDashboardPage .right {
  background: #883cae;
}

.right a.w-100 .p-card {
  height: 100%;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.right .p-card .p-card-content {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.right a.w-100:hover .p-card {
  background: #6f42c1;
  color: #fff;
  transition: 0.2s;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.right a.w-100:hover .p-card i {
  background: transparent !important;
  color: #fff;
}

.LoginPage .p-panel, .RegistrationPage .p-panel {
  max-width: 500px !important;
  margin-left: auto;
  margin-right: auto;
}

.CompanyForm .logo {
  background: #efefef;
  border-radius: 4px;
  padding: 7px;
}

.CompanyForm .logo img {
  height: 30px !important;
  width: auto !important;
}

.ReceiptList .date-column-filter .p-calendar {
  width: 113px !important;
  overflow-x: hidden;
}

.ReceiptList .type-column-filter {
  width: 120px !important;
  overflow-x: hidden;
}

.ReceiptList .nav-column-filter {
  width: 120px !important;
  overflow-x: hidden;
}

.EntryzoneLiveList .p-panel.status-PASS {
  border-left: 10px solid #28a745;
}

.EntryzoneLiveList .p-panel.status-UNID, .EntryzoneLiveList .p-panel.status-BLCK {
  border-left: 10px solid #dc3545;
}

.EntryzoneLiveList img {
  display: block;
  max-height: 195px !important;
  width: auto !important;
  margin: 0 auto !important;
}

.PurchaseorderrequestForm .amount .p-inputnumber-input {
  width: 80px !important;
}

/* layout mobile overrides */

@media (max-width: 1365px) {
  .p-menubar .p-menuitem .p-menuitem-text {
    display: none;
  }

  .p-menubar .p-menuitem .p-menuitem-icon {
    margin: 0 !important;
  }

  .p-menubar .p-submenu-list .p-menuitem-text {
    display: block !important;
  }

  .p-menubar .p-submenu-list .p-menuitem-icon {
    margin-right: 0.5rem !important;
  }

  .p-menubar-mobile-active .p-menuitem {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .p-menubar-mobile-active .p-menuitem .p-menuitem-icon {
    margin-right: 0.5rem !important;
  }

  .p-menubar-mobile-active .p-menuitem .p-menuitem-text {
    display: block !important;
  }

  .p-menubar-mobile-active .p-submenu-list .p-menuitem-text {
    display: block !important;
  }

  .p-sidebar {
    width: 100% !important;
  }

}

@media (max-width: 959px) {
  .p-menubar .p-menubar-button {
    margin-top: -5px
  }
}

@media (max-width: 960px) {
  .p-menubar {
    display: block !important;
  }

  .p-menubar .p-menubar-start {
    padding: 10px 0;
  }

  .p-menubar .p-menubar-button {
    margin-top: -40px;
    float: right !important;
  }

  .p-menubar-end {
    padding-bottom: 15px;
  }

  .p-menubar-end #companySelect {
    width: 120px;
  }
}

/* custom classes */

.text-light, .p-text-light {
  color: #fff !important;
}

.text-normal, .p-text-normal {
  font-weight: normal !important;
}

.text-info {
  color: #17a2b8
}

.text-primary {
  color: #883cae;
}

.bg-white {
  background-color: #fff !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-50 {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.w-75 {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.w-100 {
  width: 100%;
}

.transparent {
  background: transparent !important;
}

* {
  font-family: 'Open Sans', sans-serif;
}
.Kalend__main, .Kalend__main * {
  font-family: 'Open Sans', sans-serif !important;
}
input {
  font-family: 'Open Sans', sans-serif !important;
}

label {
  text-transform: none !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

*:focus, *:focus-visible, *:focus-within {
  box-shadow:none !important;
  outline: none !important;
  border-color:#0758ff !important;
}

a, a:hover, a:visited {
  color: #0758ff;
}

.p-inline-message .p-inline-message-text {
  text-align: left !important;
}

.p-inline-message.p-inline-message-warn {
  background: #ffc600 !important;
  color: #fff !important;
  text-align: left !important;
}

.p-button, button.p-button, button.p-button:hover {
  background-color: #0758ff !important;
  border-color: #0758ff !important;
}

button.p-button.p-button-outlined, .p-button-outlined:hover {
  border-color: #0758ff;
  color: #0758ff !important;
  background: transparent !important;
}

.p-button.p-button-success, button.p-button.p-button-success {
  background-color: #0758ff !important;
  border-color: #0758ff !important;
}

.p-button.p-button-warning, button.p-button.p-button-warning {
  background-color: #ffc600 !important;
  border-color: #ffc600 !important;
}

.p-button.p-button-secondary, button.p-button.p-button-secondary {
  background-color: #64748a !important;
  border-color: #64748a !important;
}

.p-checkbox {

}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0758ff !important;
  background: #0758ff !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #0758ff !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #0758ff !important;
}

.p-inputtext:enabled:hover {
  border-color: #0758ff !important;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 15px !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #0758ff !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #0758ff !important;
  box-shadow: none !important;
}

.transparent {
  background: transparent !important;
}

.p-tabview .p-tabview-nav-content ul li.p-tabview-selected a {
  border-bottom: 3px solid #0758ff !important;
  color: #0758ff !important;
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
  border: 0 !important
}

table tr, table th, table td, .p-paginator {
  background: transparent !important;
}

table .p-button-icon-only {
  border-radius:100% !important;
  padding: 0.5375rem 0.875rem !important;
}

.p-datatable .p-sortable-column.p-highlight, .p-datatable .p-sortable-column.p-highlight * {
  color: #0758ff !important;
}

.p-menubar .activeMenu {
  border-bottom: 2px solid #0758ff !important;
}

.p-menubar .activeMenu * {
  font-weight: bold;
  color: #0758ff !important;
}

.p-dialog-content {
  padding-top: 2rem !important;
}

.p-panel .p-panel-header .p-panel-title {
  color: #0758ff;
}

body.outside {
}

.LoginPage {
  background: transparent !important;
}

.LoginPage img {
  max-width:200px;
}

.LoginPage .p-panel {
  background: #fff;
  padding:30px !important;
  border-radius: 15px;
}

.LoginPage .p-text-center a.p-text-light {
  color: #0758ff !important;
}

.DashboardPage .right {
  background: #0740a5;
}

.ModuleDashboardPage .right {
  background: #0758ff;
}

.right a.w-100:hover .p-card {
  background: #5d739a;
  color: #fff;
  color: #fff;
}

.text-primary {
  color: #0758ff;
}

.EntryzoneLiveList .p-panel.status-PASS, .EntryzoneLiveList .p-panel.status-PASS * {
  background: #0758ff;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-UNID, .EntryzoneLiveList .p-panel.status-UNID * {
  background: #ffc600;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-BLCK, .EntryzoneLiveList .p-panel.status-BLCK * {
  background: #f31a26;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-STORNO, .EntryzoneLiveList .p-panel.status-STORNO * {
  background: #6c757d;
  border: 0;
  color: #fff;
}

